<template>
  <a-page-header
    style="border: 1px solid rgb(235, 237, 240)"
    :title="$t('recette.ajouterPaiement')"
    class="card"
    @back="() => $router.go(-1)"
  >
    <div class="card-header row">
      <div class="col-md-6">
        <a-select
          show-search
          style="width: 100%"
          option-filter-prop="children"
          :placeholder="$t('recette.selectionnerParent')"
          :filter-option="filterOption"
          allow-clear
          @select="selectParent"
        >
          <a-select-option
            :value="item._id"
            v-for="item in parentList"
            :key="item._id"
          >
            Mr.{{ item.father.firstName }} ET Mm.{{ item.mother.firstName }}
          </a-select-option>
        </a-select>
      </div>
      <div class="col-md-6">
        <a-select
          show-search
          style="width: 100%"
          option-filter-prop="children"
          :placeholder="$t('recette.selectionnerEnfant')"
          :filter-option="filterOption"
          allow-clear
          @select="showStudentPayment"
        >
          <a-select-option
            v-for="student in Active_studentList"
            :value="student._id"
            :key="student._id"
          >
            {{ student.firstName }} {{ student.lastName }}
          </a-select-option>
        </a-select>
      </div>
    </div>
    <a-row class="mt-5" v-if="loadingStudentPayment">
      <a-col :span="16" :offset="11">
        <a-spin size="large" />
      </a-col>
    </a-row>
    <div v-else-if="activeStudentPaymentList.active" class="card-body">
      <table class="table table-striped col-md-12">
        <thead>
          <th>{{ $t("recette.typePaiement") }}</th>
          <th>{{ $t("recette.titre") }}</th>
          <th></th>
        </thead>
        <tbody>
          <tr
            v-for="payment in activeStudentPaymentList.data"
            :key="payment._id"
          >
            <td>
              {{
                payment.typeAS == "A"
                  ? $t("recette.activite")
                  : $t("menu.scolarite")
              }}
            </td>
            <td>{{ payment.name }}</td>
            <td>
              <a-button icon="eye" @click="showModal(payment)">{{
                $t("action.afficher")
              }}</a-button>
            </td>
          </tr>
        </tbody>
      </table>
      <a-modal
        :width="976"
        v-model="visableModal"
        @cancel="cancelModel"
        :footer="false"
      >
        <payment-validator
          :data="modalData"
          :receiptTypes="receiptTypes"
          :key="reloadCount"
          :idParent="parentId"
          v-on:reload="reloadComp"
        >
        </payment-validator>
      </a-modal>
    </div>
  </a-page-header>
</template>
<script>
/* eslint-disable */
import { PageHeader, Descriptions } from "ant-design-vue";
import apiClient from "@/services/axios";
import paymentValidator from "@/views/eleves/profile/components/paymentValidator";

const getStatus = (entry) => {
  if (!entry.inscription) return "impayed";
  else {
    if (entry.once == false && entry.months)
      for (const [key, value] of Object.entries(entry.months)) {
        if (!value) return "impayed";
      }
    return "payed";
  }
};

const paymentFormater = (payment) => {
  const typeData = payment.typeAS == "A" ? payment.activity : payment.classroom;
  const name = payment.typeAS == "A" ? typeData.designation : typeData.name;
  return {
    _id: payment._id,
    student: payment.student,
    classRoom: payment.classroom,
    activity: payment.activity,
    type_id: typeData._id,
    name: name,
    once: typeData.once,
    inscription: payment.inscription,
    months: payment.months,
    defaultReduction: payment.defaultReduction,
    typeAS: payment.typeAS,
    paymentStatus: getStatus({
      inscription: payment.inscription,
      once: typeData.once,
      months: payment.months,
    }),
    typeData: typeData,
  };
};

const getMonthName = (monthString) => {
  const months = {};
  const monthsArr = moment.months();
  for (let i = 0; i < monthsArr.length; i++) months[i + 1] = monthsArr[i];

  return monthString ? months[parseInt(monthString.split("-")[1])] : null;
};

const getParentList = (students) => {
  let parents = [];
  let parentsIDs = [];
  students.forEach((student) => {
    if (student.parent) {
      if (!parentsIDs.includes(student.parent._id)) {
        parents.push(student.parent);
        parentsIDs.push(student.parent._id);
      }
    }
  });
  return parents;
};

const getActiveStudentList = (studentList) => {
  let students = [];
  let studentsIDs = [];
  studentList.forEach((student) => {
    if (!studentsIDs.includes(student._id)) {
      students.push(student);
      studentsIDs.push(student._id);
    }
  });
  return students;
};

export default {
  name: "addPayment",
  components: {
    "a-page-header": PageHeader,
    "payment-validator": paymentValidator,
    "a-descriptions": Descriptions,
  },
  async created() {
    apiClient
      .post("/receipt_types/filter", {
        query: { status: "active" },
      })
      .then((res) => (this.receiptTypes = res.data))
      .catch((e) =>
        this.$message.warning(this.$t("warning.connectionProblem"))
      );

    await apiClient.get("/payment/regesteredStudents").then((res) => {
      this.parentList = getParentList(res.data);
      this.studentList = res.data;
      this.rowStudentList = getActiveStudentList(res.data);
      this.Active_studentList = [...res.data];
    });
  },
  data() {
    return {
      studentPayments: [],
      loadingStudentPayment: false,
      modalData: {},
      visableModal: false,
      parentList: [],
      filters: { classe: false, classe_val: null, type: false, type_val: null },
      receiptTypes: [],
      studentList: [],
      rowStudentList: [],
      Active_studentList: [],
      selectedStudent: { active: false, val: null },
      selectedMode: { active: false, val: null },
      activeStudentPaymentList: { active: false, data: [] },
      disablePayment: true,
      paymentDetails: null,
      paymentTitle: "",
      reloadCount: 0,
      parentId: null,
    };
  },
  methods: {
    cancelModel() {
      this.visableModal = false;
      this.reloadCount++;
    },
    getMonthName,
    reloadComp() {
      this.$emit("reload");
    },
    showModal(val) {
      console.log(val);
      this.modalData = {
        ...val,
      };
      this.visableModal = true;
    },
    selectParent(val) {
      this.parentId = val;
      this.Active_studentList = this.rowStudentList.filter(
        (student) => student.parent._id == val
      );
    },
    async showStudentPayment(studentId) {
      this.loadingStudentPayment = true;

      this.studentPayments = [];

      await apiClient
        .post("/payment/filter", {
          query: {
            status: "active",
            student: studentId,
          },
          aggregation: [
            {
              $lookup: {
                from: "activities",
                localField: "activity",
                foreignField: "_id",
                as: "activity",
              },
            },
            {
              $unwind: { path: "$activity", preserveNullAndEmptyArrays: true },
            },
            {
              $lookup: {
                from: "classrooms",
                localField: "classroom",
                foreignField: "_id",
                as: "classroom",
              },
            },
            {
              $unwind: { path: "$classroom", preserveNullAndEmptyArrays: true },
            },
            { $unwind: "$student" },
            {
              $project: {
                activity: {
                  _id: 1,
                  once: 1,
                  designation: 1,
                  monthly: 1,
                  inscription: 1,
                },
                classroom: {
                  _id: 1,
                  name: 1,
                  monthly: 1,
                  inscription: 1,
                },
                inscription: 1,
                months: 1,
                defaultReduction: 1,
                repReduction: 1,
                typeAS: 1,
                student: 1,
              },
            },
          ],
        })
        .then((res) => {
          res.data.map((elem) => {
            const temp = paymentFormater(elem);
            this.studentPayments.push(temp);
          });

          this.activeStudentPaymentList.data = this.studentPayments.filter(
            (payment) => payment.student == studentId
          );
          this.activeStudentPaymentList.active = true;
        })
        .catch((e) => {
          console.error(e);
          this.$message.error("Aucun inscription est trouvé :");
        })
        .finally(() => {
          this.loadingStudentPayment = false;
        });
    },
    selectMode(v) {
      this.selectedMode = { active: true, val: v };
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
};
</script>
