<template>
  <reglement-caisse :key="reloadKey" v-on:reload="reload"> </reglement-caisse>
</template>
<script>
/* eslint-disable */
import reglementCaisse from "./reglementCaisse";
//reload
//paymentValidator
export default {
  components: {
    reglementCaisse,
  },
  data() {
    return { reloadKey: 0 };
  },
  methods: {
    reload() {
      this.reloadKey = this.reloadKey + 1;
    },
  },
};
</script>